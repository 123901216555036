<template>
    <div class="container-fluid">
        <select v-model="departement" class="form-select mb-3" style="width: 100%;" required v-on:change="changeDept($event)">
            <option class="w3-white" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                {{ option.text }}
            </option>
        </select>

        <div class="container-fluid pt-1 scrlt-y" v-bind:style="'height:'+  (screenHeight-135) +'px;'">
            <div class="row m-0">
                <div class="mb-2 col-md-9 col-12 m-0 p-0">
                    <div class="row container">
                        <div class="col-md-3 col-6 mb-3">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3">
                                <div class="col-md-12">
                                    <p class="h9 fw-bold mb-0">Customer</p>
                                    <p class="h8 fw-bold">{{card.customer}}</p>
                                    <p class="text-center h2 text-black-50">
                                        <span class="mdi mdi-account-multiple-outline"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-6 ">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3">
                                <div class="col-md-12">
                                    <p class="h9 fw-bold mb-0">Supplier</p>
                                    <p class="h8 fw-bold">{{card.supplier}}</p>
                                    <p class="text-center h2 text-black-50">
                                        <span class="mdi mdi-truck-cargo-container"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3">
                                <div class="col-md-12">
                                    <p class="h9 fw-bold mb-0">Invoice tgl {{day}}</p>
                                    <p class="h8 fw-bold">{{card.invoiceperday}} / {{card.amountinvoiceperday}}</p>
                                    <p class="text-center h2 text-black-50">
                                        <span class="mdi mdi-file-chart-outline"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3">
                                <div class="col-md-9">
                                    <p class="h9 fw-bold mb-0">Invoice {{month}}</p>
                                    <p class="h8 fw-bold">{{card.invoicepermonth}}</p>
                                    <p class="text-center h2 text-black-50">
                                        <span class="mdi mdi-file-chart-outline"></span>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3 overflow-hidden">
                                <div class="col-md-10">
                                    <p class="h9">Sales Order</p>
                                    <p class="h4 fw-bold">{{card.so | toRp}}</p>
                                </div>
                                <p class="text-center h1 text-black-50">
                                    <span class="mdi mdi-account-cash-outline"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3 overflow-hidden">
                                <div class="col-md-10">
                                    <p class="h9">Sales Invoice</p>
                                    <p class="h4 fw-bold">{{card.si | toRp}}</p>
                                </div>
                                <p class="text-center h1 text-black-50">
                                    <span class="mdi mdi-cash-check"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card rounded bg-light bg-gradient p-0">
                            <div class="card-header bg-transparent">
                                <p class="h8 fw-bold text-center mt-2">INVOICE</p>
                            </div>
                            <div class="p-5">
                                <line-chart :chartData="lineData" :options="lineOptions" ></line-chart>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>
                <div class="col-md-3 col-12">
                <div class="col-md-12">
                    <div class="card p-3 bg-light bg-gradient">
                        <div class="card-header bg-transparent">
                            <p class="h9 fw-bold text-uppercase">5 BEST SELLING ITEMS {{monthSub}}</p>
                        </div>
                        <div class="pt-3">
                                <pie-chart class="mb-3" :chartData="chartData" :options="chartOptions" :height="500"></pie-chart>
                                    <ul class="list-group">
                                        <li class="h9 list-group-item d-flex justify-content-between align-items-center" v-for="item in recCharts" :key="item.tm_id">
                                            {{ item.kategori }}
                                            <span class="badge bg-secondary">{{ item.qty }}</span>
                                            <span class="badge bg-primary rounded-pill">Rp.{{ item.total | toRp }}</span>
                                        </li>
                                    </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import PieChart from "./chart/PieChart.js";
import LineChart from "./chart/LineChart.js";
import moment from "moment";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Main',
    components: {
        PieChart,
        LineChart
    },
    data() {
        return {
            sales_name : localStorage.username,
            screenHeight : 0,
            day : moment(new Date()).format('DD'),
            month : moment(new Date()).format('MMM'),
            monthSub : moment().subtract(1, 'months').format('MMM YYYY'),
            card : {
                customer : 0,
                supplier : 0,
                invoiceperday : 0,
                amountinvoiceperday : 0,
                invoicepermonth : 0,
                amountinvoicepermonth : 0,
                so : 0,
                si :  0
            },
            rectargetdtl: {},
            recCharts : [],
            chartData: {},
            chartOptions: {},
            chartLabel : [],
            lineData : {},
            lineOptions : {},
            LdtNow : [],
            LdtLast : [],
            LineLabel : [],
            departement : 'SPF',
            dept: [
                { text: 'SPF', value: 'SPF' },
                { text: 'Malang', value: 'MLG' },
                { text: 'SWI', value: 'SWI' }
            ]

        }
    },
    methods : {
        changeDept(event){
            this.LoadData();
            this.getDataPie();
            this.getDataLine();
        },
        LoadData(){
            let self = this;
            let param = {
                pfunction : 'selcrontsfl',
                filter : 'main',
                dept: self.departement
            }
            axios.post("dashboard/ApiCront.php",param).then(function (response) {
                self.card.customer = response.data.rec[0].tc_nilai;
                self.card.supplier = response.data.rec[4].tc_nilai;
                self.card.invoiceperday = response.data.rec[2].tc_nilai;
                self.card.amountinvoiceperday = response.data.rec[2].tc_amount;
                self.card.invoicepermonth = response.data.rec[1].tc_nilai;
                self.card.amountinvoicepermonth = response.data.rec[1].tc_amount;
                self.card.so = response.data.rec[3].tc_amount;
                self.card.si = response.data.rec[1].tc_amount;
            });
        },
        getPercen(a, b){
            let percentage = (a * 100 / b).toFixed(2) + '%';
            return percentage;
        },
        setInit() {
            this.LoadData(this.departement);
            this.getDataPie();
            this.getDataLine();
        },
        getDataPie(){
            let self = this;
            let param = {
                pfunction : 'getmostitem',
                dept : self.departement
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.recCharts = response.data.rec;
                self.chartData = {
                    labels: response.data.rec.map(entry => entry.kategori),
                    datasets: [
                        {
                            anchor: "center",
                            borderWidth: 0,
                            backgroundColor: response.data.rec.map(entry => self.getColor()),
                            data: response.data.rec.map(entry => entry.total),
                            borderColor: "#FFFFFF",
                            hoverBorderWidth: 5,
                        }
                    ]
                }
            });
            this.chartOptions = {
                maintainAspectRatio: true,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        // label:function (tooltipItem, data) {
                        //     console.log(data);
                        //     return data['labels'][tooltipItem['index']];
                        // },
                        footer:function (tooltipItem, data) {
                            let sum = 0;
                            let dataset = data.datasets[0];
                            let currentValue = dataset.data[tooltipItem[0].index];
                            let dataArr = dataset.data;
                            dataArr.map(data => {
                                sum += Number(data);
                            });
                            let percentage = (currentValue * 100 / sum).toFixed(2) + '%';
                            return "Persentase "+percentage;
                        }
                    }
                }
            }
        },
        getDataLine(){
            let self = this;
            let param = {
                pfunction : 'selinvoice',
                dept : self.departement
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.data = response.data.recNow;
                let rdNow = self.getColor();
                let rdLast = self.getColor();

                self.lineData = {
                    labels: response.data.rec.map(entry => entry.nama),
                    datasets: [
                        {
                            label: new Date().getFullYear(),
                            data: response.data.rec.map(entry => entry.this_year_value),
                            fill: false,
                            backgroundColor : rdNow,
                            borderColor: rdNow,
                            tension: 0.2
                        },
                        {
                            label: new Date().getFullYear()-1,
                            data: response.data.rec.map(entry => entry.last_year_value),
                            fill: false,
                            backgroundColor : rdLast,
                            borderColor: rdLast,
                            tension: 0.2
                        },
                    ],
                }
            });
            self.lineOptions = {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(value, index, values) {
                                    return self.toRph(value);
                                }
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'index',
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function(c, i, a) {
                                return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
                            });
                        },
                        footer: (ttItem) => {
                            let percen = 0;
                            if (ttItem.length == 1) {
                                percen = 100+'%';
                            }else{
                                let a = parseFloat(ttItem[0]['yLabel']);
                                let b = parseFloat(ttItem[1]['yLabel']);
                                if (a < b) {
                                    percen = ((a-b)/a)*100;
                                }else{
                                    percen = ((a-b)/a)*100;
                                }
                                percen = percen.toFixed(2) + '%';
                            }
                            return "Selisih : "+percen;
                        }
                    }
                }
            }
        },
        getColor(){
            let code = '#' + Math.floor(Math.random() * 16777200).toString(16);
            return code;
        },
        toRph(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>
